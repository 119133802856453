<template>
  <div>
    <h1>Poliisid </h1>
    <p>Leitud {{ this. count}} poliisi</p>
    <br>
    <v-data-table
        :headers="headers"
        :items="policies"
        item-key="guid"
        :loading="loading"
        loading-text="Laeb... palun oota"
        :hide-default-footer=true
        :items-per-page="30"
        class="mb-4"
    >
      <template v-slot:item.status="{ item }">
        <v-chip
          class="ma-2"
          color="green"
          text-color="white"
          v-if="item.status == 'issued'"
        >
          Sõlmitud
        </v-chip>
        <v-chip
          class="ma-2"
          color="red"
          text-color="white"
          v-else-if="item.status == 'error'"
        >
          Viga
        </v-chip>
        <v-chip
          class="ma-2"
          v-else-if="item.status == 'pending'"
        >
          Pending
        </v-chip>
      </template>
      <template v-slot:item.insurer="{ item }">
        <img :src="'assets/images/insurer-logos/' + item.insurer + '.png'" width="80">
      </template>
      <template v-slot:item.service="{ item }">
        <p v-if="item.service === 'mtpl'">
          Liikluskindlustus
        </p>
        <p v-else-if="item.service === 'mtpl_unregistered'">
          Liikluskindlustus registreerimata sõiduk
        </p>
        <p v-else-if="item.service === 'casco'">
          Kaskokindlustus
        </p>
      </template>
    </v-data-table>
    <Pagination
    :pagination="this.pagination"
    :page="this.page"
    :searchQuery="this.searchQuery"
    v-on:load_new_page="loadNewPage"
    >
    </Pagination>
  </div>
</template>

<script>
import axios from "axios";
import Pagination from '../components/Pagination';
import Quotes from '../components/Quotes';
import LogDialog from '../components/LogDialog';

export default {
  name: "Policies",
  components: { LogDialog, Quotes, Pagination },
  data () {
    return {
      policies: [],
      participant: [],
      loading: true,
      expanded: [],
      headers: [
        { text: 'Selts', value: 'insurer' },
        { text: 'Staatus', value: 'status' },
        { text: 'Toode', value: 'service' },
        { text: 'Väljastamise kuupäev', value: 'issue_date'},
        { text: 'Algus', value: 'start_date'},
        { text: 'Lõpp', value: 'end_date'},
        { text: '', value: 'data-table-expand' }
      ],
      page: 1,
      count: 0,
      pagination: 0,
      logDialog: false,
      logs: []
    }
  },
  mounted () {
    this.fetchPolicies();
  },
  watch: {
    '$route.params.search': {
      handler: function() {
        this.searchQuery = this.$route.query.search;
        this.loading = true;
        this.policies = [];
        this.fetchPolicies();
      },
      deep: true,
      immediate: true
    },
  },
  methods: {
    fetchPolicies: function () {
      this.loading = true;
      this.policies = [];
      let url = axios.defaults.baseURL + '/api/policies' + '?page=' + this.page;
      if (this.searchQuery) {
        url = url + '&query=' + this.searchQuery;
      }
      axios.get(url).then((response) => {
        this.policies = response.data.policies;
        this.count = response.data.count;
        this.pagination = response.data.pages;
        this.loading = false;
      })
    },
    loadNewPage: function (page) {
      this.page = page;
      this.fetchPolicies();
    }
  }
}
</script>